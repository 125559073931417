<!--========================
Author by: Sreng Veasna
Created on: 28.08.2020
Modified:
Company: F2C V2
Description:
=========================-->
<template>
  <v-card flat width="400px">
    <v-card-title class="justify-center">Forgot Password</v-card-title>
    <v-card-text>
      <v-form>
        <InputTextField label="EMAIL ADDRESS" v-model="$v.email" />
      </v-form>
    </v-card-text>

    <v-card-actions>
      <v-btn text @click="$router.push('/login')">Back</v-btn>
      <v-btn color="primary" @click="submitForgotPwd">Submit</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";

export default {

  data() {
    return {
      email: null,
    };
  },
  mixins: [validationMixin],
  validations: {
    email: {
      required,
      email,
    },
  },
  computed: {
    isValidForgotPwd() {
      return !this.$v.fforgotpwd.$invalid;
    },
  },
  methods: {
    submitForgotPwd: function (event) {
      if (!this.isValidForgotPwd) {
        this.$v.fforgotpwd.$touch();
        event.preventDefault();
      } else {
        this.$store.commit("set", ["modalSpinnerText", "Sending..."]);
        this.$store.commit("toggle", "showHideModalSpinner");
        event.preventDefault();
        this.isShowAlert = false;
        this.alertmessage = "";
        var This = this;
        this.$http
          .post("/api/user/resetPassword", {
            email: this.fforgotpwd["email"],
          })
          .then(function (response) {
            This.$store.commit("toggle", "showHideModalSpinner");
            //console.log("Log reset password successfully:");
            This.isShowAlert = true;
            This.alertmessage =
              "I have sent you an email. Please check your email.";
          })
          .catch(function (error) {
            This.$store.commit("toggle", "showHideModalSpinner");
            //console.log("Log reset password error:");
            console.log(error.response);
            if (error.response) {
              This.isShowAlert = true;
              This.alertmessage = error.response.data.message;
            }
          });
      }
    },
    btnBacktoLogin: function (event) {
      this.$router.push("/login");
    },
  },
};
</script>
